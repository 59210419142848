import img1 from "../../assets/images/2017/airy-cube/1.jpg";
import img2 from "../../assets/images/2017/airy-cube/2.jpg";
import img3 from "../../assets/images/2017/airy-cube/3.jpg";
import img4 from "../../assets/images/2017/airy-cube/4.jpg";
import img5 from "../../assets/images/2017/airy-cube/5.jpg";
import img6 from "../../assets/images/2017/airy-cube/6.jpg";
import img7 from "../../assets/images/2017/airy-cube/7.jpg";
import img8 from "../../assets/images/2017/airy-cube/8.jpg";
import img9 from "../../assets/images/2017/airy-cube/9.jpg";
import img10 from "../../assets/images/2017/airy-cube/10.jpg";
import img11 from "../../assets/images/2017/airy-cube/11.jpg";
import img12 from "../../assets/images/2017/airy-cube/12.jpg";
import img13 from "../../assets/images/2017/airy-cube/13.jpg";
import img14 from "../../assets/images/2017/airy-cube/14.jpg";
import img15 from "../../assets/images/2017/airy-cube/15.jpg";
import img16 from "../../assets/images/2017/airy-cube/16.jpg";
function AiryCube(props) {
  return (
    <div className="App">
      <img src={img1} className="image-style-1" alt="1" />
      <img src={img2} className="image-style-1" alt="2" />
      <img src={img3} className="image-style-1" alt="3" />
      <img src={img4} className="image-style-1" alt="4" />
      <img src={img5} className="image-style-1" alt="5" />
      <img src={img6} className="image-style-1" alt="6" />
      <img src={img7} className="image-style-1" alt="7" />
      <img src={img8} className="image-style-1" alt="8" />
      <img src={img9} className="image-style-1" alt="9" />
      <img src={img10} className="image-style-1" alt="10" />
      <img src={img11} className="image-style-1" alt="11" />
      <img src={img12} className="image-style-1" alt="12" />
      <img src={img13} className="image-style-1" alt="13" />
      <img src={img14} className="image-style-1" alt="14" />
      <img src={img15} className="image-style-1" alt="15" />
      <img src={img16} className="image-style-1" alt="16" />
      <p>
        <strong>
          <em>airy cube</em>
        </strong>
        <br />
        2017
        <br />
        plastic film, hot air
        <br />
        90cm x 90cm x 90cm
      </p>
    </div>
  );
}

export default AiryCube;
