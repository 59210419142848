function Wave(props) {
  return (
    <div className="App">
      <p>
        <iframe
          width="720"
          height="405"
          src="https://www.youtube.com/embed/P_V7aZzgeUg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </p>
      <p>
        <strong>
          <em>wave</em>
        </strong>
        <br />
        2015
        <br />
        arduino + dist.sensor x 2 + step motor x 7, foam board, thin wire
        <br />
        40cm x 75cm x 30cm
        <br />
        18s
      </p>
    </div>
  );
}

export default Wave;
