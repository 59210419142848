function Wandering(props) {
  return (
    <div className="App">
      <iframe
        width="650"
        height="300"
        scrolling="no"
        frameborder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/806545555&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      ></iframe>
      <br />
      <p>
        <strong>
          <em>Wandering</em>
        </strong>
        <br />
        <strong>
          <em>6th track in『Leg​-​Quency』, compilation by Keiiti Aki</em>
        </strong>
        <br />
        2020
        <br />
        recorded sound @ Seoul, composed with SuperCollider
        <br />
        4m50s
      </p>
    </div>
  );
}

export default Wandering;
